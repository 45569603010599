import { ReachAnalysis2Api } from '@/api';
import {
  ReachAnalysisProject2CopyForm,
  ReachAnalysisProject2Overview,
  ReachAnalysisProject2OverviewCostTypeEnum
} from '@/api/openapi';
import { httpCode } from '@/common/constant';
import { REACH_ANALYSIS_ROUTES, ROUTE_NAMES } from '@/router';
import { judgeFuture } from '@/store/reachAnalysis/reachAnalysisCards';
import { useUserInfoStore } from '@/store/userInfo';
import Axios from 'axios';
import { getHours, subDays } from 'date-fns';
import { ComputedRef, Ref, computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { resolveNumber, resolveUnit } from '@/common/formatter';
import { handleError } from '@/common/handleError';
import { VALIDATION_MESSAGE } from '@/common/validation';
import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';

import { FilterMatchMode, FilterOperator } from 'primevue/api';

interface Filter {
  label: string;
  value: string;
}
interface Header {
  filter?: boolean;
  sortable?: boolean;
  id: string;
  label?: string;
  class: string;
  matchMode?: string;
  filterOptions?: Filter[];
  filterField?: string;
}
interface DataTabelFilter {
  id: string;
  operator: string;
  constraints: { value: null; matchMode: string }[];
}

interface ReachAnalysisProjectListReturnType {
  reachAnalysisData: ComputedRef<ExReachAnalysisProject2Overview[]>;
  fetchProjectList: (params: unknown) => Promise<void>;
  isFetchProjectList: Ref<boolean>;
  deleteProject: (params: {
    projectId: number;
    projectName: string;
  }) => Promise<void>;
  isDeleteProjectList: Ref<boolean>;
  copyProjectName: Ref<string>;
  copyProject: (projectId: number) => Promise<void>;
  isCopyProject: Ref<boolean>;
  validCopyProjectName: Ref<string | undefined>;
  isSelfData: Ref<boolean>;
  goToResult: (projectId: number) => Promise<void>;
  goToCreate: () => Promise<void>;
  checkFuture: (date: Date) => boolean;
}

interface DataTableReturnType {
  filters: Ref<{ [key: string]: DataTabelFilter }>;
}

export interface ExReachAnalysisProject2Overview
  extends ReachAnalysisProject2Overview {
  productList: string;
  cmSponsorList: string;
  targetList: string;
  areaOrderVal: string;
  start: Date;
  end: Date;
  condPlacementCost: string | undefined;
}

const areaList = [
  '関東',
  '関西',
  '中京',
  '北海道',
  '東北',
  '北陸・甲信越',
  '中国・四国',
  '九州・沖縄',
  '静岡',
  '宮城',
  '広島',
  '福岡'
];

export const useReachAnalysisProjectList = (
  companyId?: string
): ReachAnalysisProjectListReturnType => {
  const router = useRouter();
  const route = useRoute();
  const userInfoStore = useUserInfoStore();
  const accountId = userInfoStore.user.id;
  const reachAnalysisList = ref<ExReachAnalysisProject2Overview[]>([]);
  const isSelfData = ref(false);
  const copyProjectName = ref('');
  const today = new Date();
  // 確報日付取得
  const hour = getHours(today);
  const finalReportDay =
    0 <= hour && hour < 5 ? subDays(today, 4) : subDays(today, 3);

  const reachAnalysisData = computed(() => {
    return isSelfData.value
      ? reachAnalysisList.value.filter(v => v.authorSaasUserId === accountId)
      : reachAnalysisList.value;
  });

  const getDispPlacementCost = (data: ReachAnalysisProject2Overview) => {
    const isFuture = judgeFuture(data.endDate);
    const isPerCost =
      data.costType === ReachAnalysisProject2OverviewCostTypeEnum.PerCost;
    return isFuture && isPerCost
      ? '─'
      : data.placementCost || data.placementCost === 0
      ? resolveNumber(data.placementCost, 2) +
        resolveUnit(data.placementCost, '円')
      : undefined;
  };

  const _fetchProjectList = async () => {
    try {
      if (!companyId) {
        toast({ title: 'カンパニーが選択されていません', variant: 'error' });
        reachAnalysisList.value = [];
        return;
      }
      const res = await ReachAnalysis2Api.getCompaniesCompanyIdReachAnalysisProjects2(
        Number(companyId)
      );
      if (200 <= res.status && res.status < 300 && res.data.list) {
        const list = res.data.list?.map(v => ({
          ...v,
          productList: v.products?.join('、'),
          cmSponsorList: v.cmSponsors?.join('、'),
          targetList: v.targets?.join('、'),
          areaOrderVal: areaList.indexOf(v.areaName) + v.areaName,
          start: new Date(v.startDate),
          end: new Date(v.endDate),
          individualGrp: checkFuture(new Date(v.endDate))
            ? undefined
            : v.individualGrp,
          condPlacementCost: getDispPlacementCost(v)
        }));
        reachAnalysisList.value = await Promise.all(list);
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクト一覧の取得に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      if (e.status === httpCode.forbidden) {
        setTimeout(() => goToError(), 100);
        return;
      }
      toast({
        title: '失敗',
        message: 'プロジェクト一覧の取得に失敗しました',
        variant: 'error'
      });
    }
  };
  const [isFetchProjectList, fetchProjectList] = useLoading(_fetchProjectList);

  const _deleteProject = async ({
    projectId,
    projectName
  }: {
    projectId: number;
    projectName: string;
  }) => {
    try {
      const res = await ReachAnalysis2Api.deleteReachAnalysisProjects2ReachAnalysisProject2Id(
        projectId
      );
      if (200 <= res.status && res.status < 300) {
        fetchProjectList(companyId);
        toast({
          title: '成功',
          message: '「 ' + projectName + '」を削除しました',
          variant: 'success'
        });
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの削除に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        handleError(e, 'プロジェクトの削除に失敗しました', '');
      } else if (e.status == httpCode.forbidden) {
        toast({
          title: '失敗',
          message:
            'プロジェクトの削除に失敗しました。アクセス権限がありません。',
          variant: 'error'
        });
        goToError();
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの削除に失敗しました',
          variant: 'error'
        });
      }
    }
  };
  const [isDeleteProjectList, deleteProject] = useLoading(_deleteProject);

  const _copyProject = async (projectId: number) => {
    if (!companyId) return;
    try {
      const form: ReachAnalysisProject2CopyForm = {
        projectName: copyProjectName.value
      };

      const res = await ReachAnalysis2Api.postCompaniesCompanyIdReachAnalysisProjects2Copy(
        Number(companyId),
        projectId,
        form
      );
      if (200 <= res.status && res.status < 300) {
        fetchProjectList(companyId);
        toast({
          title: '成功',
          message: 'プロジェクトの複製に成功しました',
          variant: 'success'
        });
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの複製に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        handleError(e, 'プロジェクトの複製に失敗しました', '');
      } else if (e.status == httpCode.forbidden) {
        toast({
          title: '失敗',
          message:
            'プロジェクトの複製に失敗しました。アクセス権限がありません。',
          variant: 'error'
        });
        goToError();
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの複製に失敗しました',
          variant: 'error'
        });
      }
    }
  };
  const [isCopyProject, copyProject] = useLoading(_copyProject);

  const goToCreate = async () => {
    await router.push({
      name: REACH_ANALYSIS_ROUTES.create,
      params: { ...route.params }
    });
  };

  const goToResult = async (projectId: number) => {
    await router.push({
      name: REACH_ANALYSIS_ROUTES.result,
      params: { ...route.params, projectId }
    });
  };

  const goToError = async () => {
    await router.push({
      name: ROUTE_NAMES.error,
      params: {
        name: '権限がありません。',
        message:
          'アクセス先に権限がありません。\n連続してこのページが表示される場合は管理者にお問い合わせください。',
        back: undefined,
        status: '403 forbidden'
      },
      force: true
    });
  };

  const checkFuture = (date: Date) => {
    return date.getTime() > finalReportDay.getTime();
  };

  const validCopyProjectName: Ref<string | undefined> = computed(() => {
    if (copyProjectName.value.length > 64) {
      return VALIDATION_MESSAGE.max64;
    }
    if (copyProjectName.value.length && !copyProjectName.value.match(/\S/g)) {
      return VALIDATION_MESSAGE.invalidChar;
    }
    return undefined;
  });

  return {
    reachAnalysisData,
    fetchProjectList,
    isFetchProjectList,
    deleteProject,
    isDeleteProjectList,
    copyProjectName,
    copyProject,
    isCopyProject,
    validCopyProjectName,
    isSelfData,
    goToResult,
    goToCreate,
    checkFuture
  };
};

const STR_FILTER: Filter[] = [
  { label: '等しい', value: FilterMatchMode.EQUALS },
  { label: '等しくない', value: FilterMatchMode.NOT_EQUALS },
  { label: '含む', value: FilterMatchMode.CONTAINS },
  { label: '含まない', value: FilterMatchMode.NOT_CONTAINS }
];
const NUM_FILTER: Filter[] = [
  { label: '以上', value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
  { label: '以下', value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
  { label: 'より大きい', value: FilterMatchMode.GREATER_THAN },
  { label: 'より小さい', value: FilterMatchMode.LESS_THAN }
];
const DATE_FILTER: Filter[] = [
  { label: '等しい', value: FilterMatchMode.DATE_IS },
  { label: '含まない', value: FilterMatchMode.DATE_IS_NOT },
  { label: '以前', value: FilterMatchMode.DATE_BEFORE },
  { label: 'より後', value: FilterMatchMode.DATE_AFTER }
];
export const HEADERS: Header[] = [
  {
    filter: true,
    sortable: true,
    filterField: 'string',
    id: 'name',
    label: 'プロジェクト名',
    class: 'p-sortable-column--lg',
    matchMode: FilterMatchMode.CONTAINS,
    filterOptions: STR_FILTER
  },
  {
    filter: true,
    sortable: true,
    filterField: 'string',
    id: 'productList',
    label: '商品／ブランド',
    class: 'p-sortable-column--lg',
    matchMode: FilterMatchMode.CONTAINS,
    filterOptions: STR_FILTER
  },
  {
    filter: true,
    sortable: true,
    filterField: 'string',
    id: 'cmSponsorList',
    label: '企業',
    class: 'p-sortable-column--lg',
    matchMode: FilterMatchMode.CONTAINS,
    filterOptions: STR_FILTER
  },
  {
    filter: true,
    sortable: true,
    filterField: 'string',
    id: 'targetList',
    label: 'ターゲット',
    class: 'p-sortable-column--lg',
    matchMode: FilterMatchMode.CONTAINS,
    filterOptions: STR_FILTER
  },
  {
    filter: true,
    sortable: true,
    filterField: 'string',
    id: 'areaOrderVal',
    label: 'エリア',
    class: 'p-sortable-column--sh center',
    matchMode: FilterMatchMode.CONTAINS,
    filterOptions: STR_FILTER
  },
  {
    filter: true,
    sortable: true,
    filterField: 'date',
    id: 'start',
    label: '開始日',
    class: 'p-sortable-column--sh center',
    matchMode: FilterMatchMode.DATE_IS,
    filterOptions: DATE_FILTER
  },
  {
    filter: true,
    sortable: true,
    filterField: 'date',
    id: 'end',
    label: '終了日',
    class: 'p-sortable-column--sh center',
    matchMode: FilterMatchMode.DATE_IS,
    filterOptions: DATE_FILTER
  },
  {
    filter: true,
    sortable: true,
    filterField: 'number',
    id: 'individualGrp',
    label: '個人GRP',
    class: 'p-sortable-column--xsh end',
    matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
    filterOptions: NUM_FILTER
  },
  {
    id: 'menu',
    class: 'p-sortable-column--ssm last-colum'
  }
];

export const useDataTable = (): DataTableReturnType => {
  const filters = ref();
  const tmp = HEADERS.map(v => {
    if (!v.filter) return;
    return {
      id: v.id,
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: v.matchMode }]
    };
  }).filter(Boolean) as DataTabelFilter[];
  filters.value = tmp.reduce((p, c) => {
    return { ...p, [c.id]: c };
  }, {});

  return {
    filters
  };
};
