import {
  ReachAnalysisProject2CardListCards,
  ReachAnalysisProject2SectionStatusSectionEnum
} from '@/api/openapi';
import { getHours, isAfter, subDays } from 'date-fns';

type ValueOf<T> = T[keyof T];

export const GraphList = {
  cpeComparison: 'CPE_COMPARISON',
  effectiveReach: 'EFFECTIVE_REACH'
} as const;
export type GraphType = ValueOf<typeof GraphList>;

export const HeatMap = {
  cmHeatMap: 'CM_HEATMAP'
} as const;
export type HeatMapType = ValueOf<typeof HeatMap>;

export const CheckSelectionList = {
  ...GraphList,
  ...HeatMap,
  none: undefined
} as const;
export type CheckSelection = ValueOf<typeof CheckSelectionList>;

export const cardMax = 5;
const cmHeatMapDispCardMax = 2;

export type SectionType = {
  dispCard: {
    mainCardId: number | undefined;
    subCardIds: number[];
  };
  dispCardMax: number;
  aggregatingIds: number[] | undefined;
  referableIds: number[] | undefined;
};

export type SectionData = {
  [K in GraphType | HeatMapType]: SectionType;
};

const getSectionDatabase = () => {
  const sectionDatabase: SectionType = {
    dispCard: {
      mainCardId: undefined,
      subCardIds: []
    },
    dispCardMax: cardMax,
    aggregatingIds: undefined,
    referableIds: undefined
  };
  return sectionDatabase;
};

export const initSectionData = (): SectionData => {
  return {
    CPE_COMPARISON: getSectionDatabase(),
    EFFECTIVE_REACH: getSectionDatabase(),
    CM_HEATMAP: {
      ...getSectionDatabase(),
      dispCardMax: cmHeatMapDispCardMax
    }
  };
};

export interface exCard extends ReachAnalysisProject2CardListCards {
  dispPlacementCost: number | undefined;
  isFuture: boolean;
}

export const judgeFuture = (val: string): boolean => {
  const today = new Date();
  const hour = getHours(today);
  const finalReportDay =
    0 <= hour && hour < 5 ? subDays(today, 4) : subDays(today, 3);
  const date = new Date(val);
  return isAfter(date, finalReportDay);
};

export const ConvertToSectionEnum = (
  CheckSelection: CheckSelection
): ReachAnalysisProject2SectionStatusSectionEnum | undefined => {
  switch (CheckSelection) {
    case GraphList.cpeComparison:
      return ReachAnalysisProject2SectionStatusSectionEnum.CpeComparison;
    case GraphList.effectiveReach:
      return ReachAnalysisProject2SectionStatusSectionEnum.EffectiveReach;
    case HeatMap.cmHeatMap:
      return ReachAnalysisProject2SectionStatusSectionEnum.CmHeatmap;
    default:
      return undefined;
  }
};
