import {
  ReachAnalysisProject2CardDetailCmBuyingKindEnum,
  ReachAnalysisProject2OverviewCmBuyingKindEnum,
  WorkspaceWorkspaceTypeEnum
} from '@/api/openapi';
import { useWorkspaceType } from '@/composables/workspace';
import { COMPANY_ROUTES } from '@/router';

interface ReachAnalysis {
  breadcrumbs: {
    parents: Array<{ name: string; label: string }>;
    current: { label: string };
  };
  getIsCmCompany: () => Promise<boolean>;
}

export const useCReachAnalysis = (): ReachAnalysis => {
  const BREADCRUMBS = {
    parents: [
      { name: COMPANY_ROUTES.top, label: 'ホーム' },
      { name: '', label: 'エフェクティブリーチ' }
    ],
    current: { label: 'リーチ分析' }
  };

  const { getCurrentWorkspaceType } = useWorkspaceType();
  const getIsCmCompany = async () => {
    const workspaceType = await getCurrentWorkspaceType();
    return workspaceType === WorkspaceWorkspaceTypeEnum.CmCompany;
  };
  return {
    breadcrumbs: BREADCRUMBS,
    getIsCmCompany
  };
};

export const convertBuyingKindValue = (
  buyingKind:
    | ReachAnalysisProject2OverviewCmBuyingKindEnum
    | ReachAnalysisProject2CardDetailCmBuyingKindEnum
): string => {
  switch (buyingKind) {
    case ReachAnalysisProject2OverviewCmBuyingKindEnum.All:
      ReachAnalysisProject2CardDetailCmBuyingKindEnum.All;
      return 'タイム＋スポット';
    case ReachAnalysisProject2OverviewCmBuyingKindEnum.Time:
      ReachAnalysisProject2CardDetailCmBuyingKindEnum.Time;
      return 'タイムのみ';
    case ReachAnalysisProject2OverviewCmBuyingKindEnum.Spot:
      ReachAnalysisProject2CardDetailCmBuyingKindEnum.Spot;
      return 'スポットのみ';
    default:
      return 'タイム＋スポット';
  }
};
